<template>
  <v-container>
    <cause-list/>
  </v-container>
</template>

<script>
import CauseList from "@/components/app_setting/cause/CauseList";

export default {
  name: "CausePage",
  components: {
    CauseList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Causes'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>